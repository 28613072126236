import React, { useRef } from 'react'
import {
  Card,
  Form,
  Button,
  Modal,
  Select,
  Space,
  Radio,
  Table,
  Drawer,
  ConfigProvider,
  Breadcrumb,
  Input,
  message,
  Tooltip,
} from 'antd'
import styles from './index.module.scss'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCompLists } from '../../../../store/actions/comp'
import { useState } from 'react'
import zhCH from 'antd/lib/locale/zh_CN'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { getSuperGroup } from '../../../../utils/cookie'
import { useGetState, useSize } from 'ahooks'
import {
  addConfigManage,
  delConfigs,
  editConfigManage,
  getConfigManageList,
  getTrigger,
} from '../../../../store/actions/cfg'
import MyButton from '../../../../components/MyButton'
export default function ConfigManage() {
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)
  const [delconfig, setDelConfig] = useState(false)
  const [watch, setWatch] = useState(false)
  const [configInfo, setConfigInfo, getConfigInfo] = useGetState({})
  const [coId, setCoId, getCoId] = useGetState('')
  const [configCoId, setConfigCoId] = useState('')
  const tableRef = useRef(null)
  const size = useSize(tableRef)
  const userInfo = useSelector((state) => state.login.userInfo)
  const rulesObj = useRef({
    co_id: [
      {
        required: true,
        message: '请选择企业/产品',
      },
    ],
    cfg_code: [
      {
        required: true,
        message: '配置项编码不能为空',
      },
      {
        pattern: /^[^\s]+(\s+[^\s]+)*/,
        message: '不能以空格开头',
      },
      {
        validator(_, value) {
          const id = formRef.current.getFieldValue(['co_id'])
          console.log(getCoId(), getConfigInfo())
          if ((id && value) || !(userInfo?.co_type_code === 'ADMIN_GROUP')) {
            return dispatch(
              getTrigger(
                {
                  pk: getConfigInfo().cfg_id ? getConfigInfo().cfg_id : null,
                  co_id: id
                    ? id
                    : getCoId()
                    ? getCoId()
                    : getConfigInfo().co_id,
                  field: 'code',
                  value,
                },
                false
              )
            ).then(({ data }) => {
              if (!data.data) {
                return Promise.resolve()
              }
              return Promise.reject(new Error('配置项编码已存在'))
            })
          }
        },
        validateTrigger: 'onBlur',
      },
    ],
    cfg_name: [
      {
        required: true,
        message: '配置项名称不能为空',
      },
      {
        pattern: /^[^\s]+(\s+[^\s]+)*/,
        message: '不能以空格开头',
      },
    ],
    cfg_value: [
      {
        required: true,
        message: '配置项值不能为空',
      },
      {
        pattern: /^[^\s]+(\s+[^\s]+)*/,
        message: '不能以空格开头',
      },
    ],
    cfg_desc: [
      {
        pattern: /^[^\s]+(\s+[^\s]+)*/,
        message: '不能以空格开头',
      },
    ],
  })

  //获取配置管理列表
  useEffect(() => {
    dispatch(getConfigManageList())
  }, [dispatch])

  useEffect(() => {
    userInfo?.co_type_code === 'ADMIN_GROUP' &&
      dispatch(
        getCompLists({
          is_page: true,
        })
      )
  }, [dispatch])

  useEffect(() => {
    setTimeout(() => {
      formRef.current?.setFieldsValue({
        ...configInfo,
        co_id: configInfo.co_id
          ? configInfo.co_id
          : configCoId
          ? configCoId
          : null,
      })
    }, 0)
  }, [configInfo])
  const { total, configManageList } = useSelector((state) => state.cfg)
  const paramsRef = useRef({
    page: 1,
    page_size: 10,
  })
  useEffect(() => {
    paramsRef.current.page = 1
    paramsRef.current.page_size = 10
  }, [])

  const { compListAll } = useSelector((state) => state.comp)

  const columns =
    userInfo?.co_type_code === 'ADMIN_GROUP'
      ? [
          {
            title: '序号',
            dataIndex: 'index',
            width: 80,
            ellipsis: {
              showTitle: false,
            },
            render(text, record, index) {
              return (
                <span>
                  {(paramsRef.current.page - 1) * paramsRef.current.page_size +
                    index +
                    1}
                </span>
              )
            },
          },
          {
            title: '企业/产品',
            dataIndex: 'co_name',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '配置项编码',
            dataIndex: 'cfg_code',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '配置项名称',
            dataIndex: 'cfg_name',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '配置项值',
            dataIndex: 'cfg_value',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '描述',
            dataIndex: 'cfg_desc',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip
                overlayStyle={{ maxWidth: 560 }}
                placement="leftTop"
                title={text}
              >
                {text}
              </Tooltip>
            ),
          },
          {
            title: '创建人',
            dataIndex: 'created_by_name',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '创建时间',
            dataIndex: 'created_time',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '修改时间',
            dataIndex: 'updated_time',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '操作',
            key: 'confg',
            width: 150,
            fixed: 'right',
            render(confg) {
              if (confg.is_preset) {
                return (
                  <Space>
                    <Button
                      type="link"
                      className="edit"
                      onClick={() => {
                        setConfigInfo(confg)
                        setWatch(true)
                      }}
                    >
                      查看
                    </Button>
                  </Space>
                )
              }
              return (
                <Space>
                  <Button
                    type="link"
                    className="edit"
                    onClick={() => {
                      setConfigInfo(confg)
                      setWatch(true)
                    }}
                  >
                    查看
                  </Button>
                  <Button
                    type="link"
                    className="edit"
                    onClick={() => editConfig(confg)}
                  >
                    修改
                  </Button>
                  <Button
                    type="link"
                    className="del"
                    onClick={() => delConfig(confg)}
                  >
                    删除
                  </Button>
                </Space>
              )
            },
          },
        ]
      : [
          {
            title: '序号',
            dataIndex: 'index',
            width: 80,
            ellipsis: {
              showTitle: false,
            },
            render(text, record, index) {
              return (
                <span>
                  {(paramsRef.current.page - 1) * paramsRef.current.page_size +
                    index +
                    1}
                </span>
              )
            },
          },
          {
            title: '配置项编码',
            dataIndex: 'cfg_code',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '配置项名称',
            dataIndex: 'cfg_name',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '配置项值',
            dataIndex: 'cfg_value',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '描述',
            dataIndex: 'cfg_desc',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip
                overlayStyle={{ maxWidth: 560 }}
                placement="leftTop"
                title={text}
              >
                {text}
              </Tooltip>
            ),
          },
          {
            title: '创建人',
            dataIndex: 'create_by',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render(is_preset) {
              return is_preset ? (
                <div className="is-true">是</div>
              ) : (
                <div className="is-false">否</div>
              )
            },
          },
          {
            title: '创建时间',
            dataIndex: 'created_time',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '修改时间',
            dataIndex: 'updated_time',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '操作',
            key: 'confg',
            width: 150,
            fixed: 'right',
            render(confg) {
              if (confg.is_preset) {
                return (
                  <Space>
                    <Button
                      type="link"
                      className="edit"
                      onClick={() => {
                        setConfigInfo(confg)
                        setWatch(true)
                      }}
                    >
                      查看
                    </Button>
                  </Space>
                )
              }
              return (
                <Space>
                  <Button
                    type="link"
                    className="edit"
                    onClick={() => {
                      setConfigInfo(confg)
                      setWatch(true)
                    }}
                  >
                    查看
                  </Button>
                  <Button
                    type="link"
                    className="edit"
                    onClick={() => editConfig(confg)}
                  >
                    修改
                  </Button>
                  <Button
                    type="link"
                    className="del"
                    onClick={() => delConfig(confg)}
                  >
                    删除
                  </Button>
                </Space>
              )
            },
          },
        ]

  // 新增配置弹框
  const addConfig = () => {
    console.log('新增配置')
    setVisible(true)
    setConfigInfo({})
  }
  const formRef = useRef(null)
  //修改配置
  const editConfig = (cfg) => {
    console.log('修改配置', cfg)
    cfg = JSON.parse(JSON.stringify(cfg))
    setConfigInfo(cfg)
    setVisible(true)
  }

  // 删除配置弹框并获取配置信息并保存
  const delConfig = (co) => {
    setConfigInfo(co)
    setDelConfig(true)
  }
  //选择框
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(selectedRowKeys, selectedRows)
    },
  }
  // 新增配置
  const onFinish = async (values) => {
    console.log(values)
    if (configInfo.cfg_id) {
      await dispatch(editConfigManage({ ...values, id: configInfo.cfg_id }))
    } else {
      await dispatch(addConfigManage(values))
    }
    message.success(configInfo.cfg_id ? '修改配置成功' : '新增配置成功')
    setVisible(false)
    paramsRef.current.page = 1
    await dispatch(
      getConfigManageList({
        ...paramsRef.current,
        co_id: configCoId ? configCoId : null,
      })
    )
  }
  // 删除配置
  const delConfigManage = async (id) => {
    console.log(id)
    await dispatch(delConfigs(id))
    setDelConfig(false)
    message.success('删除配置成功')
    paramsRef.current.page = 1
    dispatch(getConfigManageList(paramsRef.current))
  }

  // 搜索配置
  const searchConfig = async (values) => {
    console.log(values)
    setConfigCoId(values.co_id)
    paramsRef.current.co_id = values.co_id
    paramsRef.current.fuzzy_name = values.fuzzy_name
    paramsRef.current.page = 1
    await dispatch(getConfigManageList(paramsRef.current))
  }
  // 分页处理
  const onChange = (page, pageSize) => {
    console.log(page, pageSize)
    paramsRef.current.page = page.current
    paramsRef.current.page_size = page.pageSize
    paramsRef.current.current = page.defaultCurrent
    dispatch(getConfigManageList(paramsRef.current))
  }
  const paginations = {
    defaultCurrent: 1,
    current: paramsRef.current.page,
    defaultPageSize: 10,
    total: total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    showTotal: (total) => {
      return `共${total}条`
    },
  }
  const [form] = Form.useForm()
  const onReset = () => {
    form.resetFields()
    setConfigCoId('')
  }

  const changeComp = (value) => {
    console.log(value)
    setCoId(value)
  }

  return (
    <div className={styles.root}>
      <div className="right_content">
        <Breadcrumb>
          <Breadcrumb.Item>系统管理</Breadcrumb.Item>
          <Breadcrumb.Item>配置管理</Breadcrumb.Item>
        </Breadcrumb>
        <Card className="search-card">
          <Form
            colon={false}
            form={form}
            autoComplete="off"
            className="form-search"
            onFinish={searchConfig}
          >
            <div className="flex">
              {userInfo?.co_type_code === 'ADMIN_GROUP' ? (
                <Form.Item label="企业/产品" name="co_id">
                  <Select
                    onChange={changeComp}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    placeholder="请选择企业/产品"
                    style={{ width: 238 }}
                  >
                    {compListAll.map((item) => (
                      <Select.Option value={item.co_id} key={item.co_id}>
                        {item.co_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                ''
              )}
              <Form.Item label="配置项" name="fuzzy_name">
                <Input
                  placeholder="请输入配置项编码或名称"
                  style={{ width: 238 }}
                ></Input>
              </Form.Item>
            </div>
            <Form.Item>
              <Space size={[20]}>
                <MyButton type="default" htmlType="submit">
                  查询
                </MyButton>
                <MyButton onClick={onReset}>重置</MyButton>
              </Space>
            </Form.Item>
          </Form>
        </Card>
        <Space size={[24]} className="space-heigth">
          <MyButton type="default" onClick={addConfig}>
            新增
          </MyButton>
        </Space>
        <div className="right_table" ref={tableRef}>
          <ConfigProvider locale={zhCH}>
            <Table
              scroll={{ y: size?.height - 95 + 'px' }}
              size="small"
              onChange={onChange}
              rowKey="cfg_id"
              rowSelection={{ ...rowSelection }}
              columns={columns}
              dataSource={configManageList}
              showCount
              pagination={paginations}
            ></Table>
          </ConfigProvider>
        </div>
      </div>
      {/* 查看配置 */}
      <Drawer
        destroyOnClose={true}
        width={624}
        title="查看配置"
        placement="right"
        onClose={() => setWatch(false)}
        visible={watch}
      >
        <div style={{ minHeight: 'calc(100% - 30px)' }}>
          <div className="msg">
            <span className="linear"></span> 基本信息
          </div>
          <div className="watch_marg">
            {userInfo?.co_type_code === 'ADMIN_GROUP' ? (
              <p>
                <span>企业/产品</span>
                {configInfo.co_name || '-'}
              </p>
            ) : (
              ''
            )}
            <p>
              <span>配置项编码</span>
              {configInfo.cfg_code || '-'}
            </p>
            <p>
              <span>配置项名称</span>
              {configInfo.cfg_name || '-'}
            </p>
            <p>
              <span>配置项值</span>
              {configInfo.cfg_value || '-'}
            </p>
            <p className="desc">
              <span>描述</span>
              <Tooltip
                overlayStyle={{ maxWidth: 560 }}
                placement="leftTop"
                className="desc_text"
                title={configInfo.cfg_desc}
              >
                {configInfo.cfg_desc || '-'}
              </Tooltip>
            </p>
          </div>
        </div>
        <MyButton
          className="btnColor ant-spaces"
          onClick={() => setWatch(false)}
        >
          关闭
        </MyButton>
      </Drawer>
      {/* 新增配置 */}
      <Drawer
        width={624}
        destroyOnClose={true}
        size="large"
        maskClosable={false}
        title={configInfo.cfg_id ? '修改配置' : '新增配置'}
        placement="right"
        onClose={() => {
          setVisible(false)
        }}
        visible={visible}
      >
        <Form
          colon={false}
          autoComplete="off"
          validateTrigger={['onBlur', 'onChange']}
          ref={formRef}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 18 }}
          initialValues={{
            is_default_tmpl: false,
          }}
          onFinish={onFinish}
        >
          <div className="form-btn">
            <div className="flex">
              {userInfo?.co_type_code === 'ADMIN_GROUP' ? (
                <Form.Item
                  label="企业/产品"
                  name="co_id"
                  rules={rulesObj.current.co_id}
                >
                  <Select
                    onChange={() => {
                      changeComp()
                      formRef.current.validateFields(['cfg_code'])
                    }}
                    disabled={configInfo.cfg_id ? true : false}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    placeholder="请选择企业/产品"
                    style={{ width: 238 }}
                  >
                    {compListAll.map((item) => (
                      <Select.Option value={item.co_id} key={item.co_id}>
                        {item.co_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                ''
              )}
              <Form.Item
                className="w-50"
                label="配置项编码"
                name="cfg_code"
                rules={rulesObj.current.cfg_code}
              >
                <Input
                  maxLength={50}
                  placeholder="请输入配置项编码，不超过50个字"
                ></Input>
              </Form.Item>
            </div>

            <Form.Item
              label="配置项名称"
              name="cfg_name"
              rules={rulesObj.current.cfg_name}
            >
              <Input
                maxLength={50}
                placeholder="请输入配置项名称，不超过50个字"
              ></Input>
            </Form.Item>
            <Form.Item
              label="配置项值"
              name="cfg_value"
              rules={rulesObj.current.cfg_value}
            >
              <Input
                maxLength={500}
                placeholder="请输入配置项值，不超过500个字"
              ></Input>
            </Form.Item>
            <Form.Item
              label="描述"
              name="cfg_desc"
              rules={rulesObj.current.cfg_desc}
            >
              <Input.TextArea
                maxLength={500}
                showCount
                placeholder="请输入配置描述，不超过500个字"
                style={{ height: '100px' }}
              ></Input.TextArea>
            </Form.Item>
          </div>

          <Form.Item>
            <div className="flex_end">
              <Space>
                <MyButton onClick={() => setVisible(false)}>取消</MyButton>
                <MyButton type="default" htmlType="submit">
                  {configInfo.cfg_id ? '修改' : '新增'}
                </MyButton>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Drawer>
      {/* 删除配置对话框 */}
      <Modal
        bodyStyle={{ textAlign: 'center' }}
        centered
        onCancel={() => setDelConfig(false)}
        title="删除配置"
        visible={delconfig}
        getContainer={false}
        footer={[
          <Space size={[24]} className="model-btn">
            <MyButton onClick={() => setDelConfig(false)}>取消</MyButton>
            <MyButton
              type="default"
              onClick={() => delConfigManage(configInfo.cfg_id)}
            >
              删除
            </MyButton>
          </Space>,
        ]}
      >
        <p>
          <ExclamationCircleOutlined
            style={{ fontSize: 36, color: '#fe545f' }}
          />
        </p>
        <p>确定要删除配置项"{configInfo.cfg_name}"吗？</p>
      </Modal>
    </div>
  )
}
