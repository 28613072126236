import React, { useEffect, useRef, useState } from 'react'
import styles from './index.module.scss'
import {
  Breadcrumb,
  Card,
  Form,
  Select,
  Space,
  Table,
  Button,
  Drawer,
  Input,
  DatePicker,
  ConfigProvider,
  Modal,
  Tooltip,
} from 'antd'
import MyButton from '../../../../components/MyButton'
import { useSize } from 'ahooks'
import { Editor } from '@tinymce/tinymce-react'
import zhCN from 'antd/es/locale/zh_CN'
import {
  addUpdates,
  deleteUpdates,
  editUpdates,
  getUpdateLogList,
} from '../../../../store/actions/updates'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { getIntegrationList } from '../../../../store/actions/comp'
export default function UpdateLogManage() {
  const tableRef = useRef(null)
  const userForm = useRef()
  const [form] = Form.useForm()
  const size = useSize(tableRef)
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)
  const [delLog, setDelLog] = useState(false)
  const [logInfo, setLogInfo] = useState({})
  const [time, setTime] = useState()
  const paramsRef = useRef({
    page: 1,
    page_size: 10,
  })
  const { total, updatelogList } = useSelector((state) => state.updates)
  const { integrationList, defaultSysId } = useSelector((state) => state.comp)
  const [defaultId, setDefaultId] = useState(
    localStorage.getItem('defaultSysId')
  )
  const [sysId, setSysId] = useState('')
  useEffect(() => {
    dispatch(
      getIntegrationList({
        is_page: true,
      })
    )
  }, [dispatch])
  useEffect(() => {
    dispatch(
      getUpdateLogList({
        ordering: '-update_date',
        sys_id: defaultId,
      })
    )
    form.setFieldsValue({
      sys_id: defaultId,
    })
  }, [dispatch])

  useEffect(() => {
    paramsRef.current.page = 1
    paramsRef.current.page_size = 10
  }, [])

  // 数据回显
  useEffect(() => {
    const values = {
      ...logInfo,
    }
    setTimeout(() => {
      userForm.current?.setFieldsValue({
        ...values,
        sys_id: logInfo.sys_id
          ? logInfo.sys_id
          : defaultId
          ? defaultId
          : sysId
          ? sysId
          : null,
      })
    }, 0)
  }, [logInfo, sysId])

  const paginations = {
    defaultCurrent: 1,
    current: paramsRef.current.page,
    defaultPageSize: 10,
    total: total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    showTotal: (total) => {
      return `共${total}条`
    },
  }

  const onChange = (page, pageSize) => {
    paramsRef.current.sys_id = sysId || defaultId
    paramsRef.current.page = page.current
    paramsRef.current.page_size = page.pageSize
    paramsRef.current.current = page.defaultCurrent
    dispatch(getUpdateLogList({ ...paramsRef.current }))
  }

  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      width: 60,
      render(text, record, index) {
        return (
          <span>
            {(paramsRef.current.page - 1) * paramsRef.current.page_size +
              index +
              1}
          </span>
        )
      },
    },
    { title: '系统编号', dataIndex: 'sys_code', width: 80 },
    { title: '系统名称', dataIndex: 'sys_name', width: 100 },
    { title: '更新日期', dataIndex: 'update_date', width: 120 },
    { title: '版本号', dataIndex: 'ver_no', width: 80 },
    {
      title: '更新日志',
      dataIndex: 'update_log',
      width: 220,
      ellipsis: true,
      render: (date) => {
        const tempDiv = document.createElement('div')
        tempDiv.innerHTML = date
        const textContent = tempDiv.textContent || tempDiv.innerText
        return (
          <Tooltip placement="leftTop" title={textContent}>
            <span>{textContent}</span>
          </Tooltip>
        )
      },
    },
    { title: '创建人', dataIndex: 'created_by_name', width: 80 },
    { title: '创建时间', dataIndex: 'created_time', width: 120 },
    { title: '修改时间', dataIndex: 'updated_time', width: 120 },
    {
      title: '操作',
      fixed: 'right',
      width: 80,
      render(info) {
        return (
          <Space>
            <Button
              type="link"
              className="edit"
              onClick={() => {
                setLogInfo({
                  ...info,
                  update_date: moment(info?.update_date),
                })
                localStorage.setItem('editorVal', info?.update_log)
                setVisible(true)
                setTime()
              }}
            >
              修改
            </Button>
            <Button
              type="link"
              className="del"
              onClick={() => {
                setDelLog(true)
                setLogInfo(info)
              }}
            >
              删除
            </Button>
          </Space>
        )
      },
    },
  ]

  const changeSystem = (val) => {
    setSysId(val)
  }

  const search = async (values) => {
    setDefaultId('')

    paramsRef.current.page = 1
    await dispatch(getUpdateLogList(values))
  }

  const onFinish = async (values) => {
    setTime()
    values.update_date = time ? time : moment(logInfo.update_date).format()
    values.update_log_id = logInfo.update_log_id ? logInfo.update_log_id : null
    values.update_log = localStorage.getItem('editorVal') || null
    values.ver_no = values.ver_no || null
    if (logInfo.sys_code) {
      await dispatch(editUpdates(values))
    } else {
      await dispatch(addUpdates(values))
    }
    setVisible(false)
    localStorage.removeItem('editorVal')
    await dispatch(getUpdateLogList({ sys_id: defaultId || sysId }))
  }

  const changeDate = (_, time) => {
    setTime(_._d)
  }
  const disabledDate = (current) => {
    // 禁用今天及以后的日期
    return current && current > moment().endOf('day')
  }

  const RichFile = (props) => {
    const dispatch = useDispatch()
    const [value, setValue] = useState(logInfo.update_log)
    const onValueChange = (value) => {
      localStorage.setItem('editorVal', value)
      setValue(value)
    }
    return (
      <div className={styles['welcomeContainer']}>
        <Editor
          value={value}
          init={{
            language: 'zh_CN',
            fontsize_formats: '12px 14px 16px 18px 24px 36px 48px 56px 72px',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],
            // eslint-disable-next-line no-multi-str
            toolbar:
              'undo redo | formatselect |fontselect fontsizeselect | bold italic backcolor forecolor | \
                   alignleft aligncenter alignright alignjustify | \
                   bullist numlist outdent indent | removeformat | help',
          }}
          onEditorChange={onValueChange}
        />
      </div>
    )
  }

  return (
    <div className={styles.root}>
      <div className="right_content">
        <Breadcrumb>
          <Breadcrumb.Item>系统管理</Breadcrumb.Item>
          <Breadcrumb.Item>更新日志管理</Breadcrumb.Item>
        </Breadcrumb>
        <Card className="search_card">
          <Form
            colon={false}
            className="form-search"
            form={form}
            onFinish={search}
          >
            <div className="flex">
              <Form.Item label="系统名称" name="sys_id">
                <Select
                  onChange={changeSystem}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  placeholder="请选择系统名称"
                  style={{ width: 238 }}
                  value={defaultId}
                >
                  {integrationList.map((item, index) => (
                    <Select.Option value={item.sys_id} key={index}>
                      {item.sys_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <Form.Item>
              <Space size={[24]}>
                <MyButton type="default" htmlType="submit">
                  查询
                </MyButton>
              </Space>
            </Form.Item>
          </Form>
        </Card>
        <Space size={[24]} className="space-heigth">
          <MyButton
            type="default"
            onClick={() => {
              setVisible(true)
              setLogInfo({})
              setTime()
              localStorage.removeItem('editorVal')
            }}
          >
            + 新增
          </MyButton>
        </Space>
        <div className="right_table" ref={tableRef}>
          <ConfigProvider locale={zhCN}>
            <Table
              scroll={{ y: size?.height - 95 + 'px' }}
              size="small"
              onChange={onChange}
              rowKey={(i) => {
                return i.co_user_id
              }}
              columns={columns}
              dataSource={updatelogList}
              pagination={paginations}
            ></Table>
          </ConfigProvider>
        </div>
      </div>
      {/* 新增用户 */}
      <Drawer
        width={624}
        destroyOnClose={true}
        size="large"
        maskClosable={false}
        title={logInfo.sys_code ? '修改更新日志' : '新增更新日志'}
        placement="right"
        onClose={() => {
          setVisible(false)
        }}
        visible={visible}
      >
        <ConfigProvider locale={zhCN}>
          <Form
            colon={false}
            autoComplete="off"
            ref={userForm}
            validateTrigger={['onBlur', 'onChange']}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 18 }}
            onFinish={onFinish}
          >
            <div className="form-btn">
              <Form.Item
                label="系统名称"
                name="sys_id"
                rules={[
                  {
                    required: true,
                    message: '请选择系统名称',
                  },
                ]}
              >
                <Select
                  placeholder="请选择系统名称"
                  disabled={logInfo.sys_code ? true : false}
                >
                  {integrationList.map((item, index) => (
                    <Select.Option value={item.sys_id} key={index}>
                      {item.sys_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <div className="flex">
                <Form.Item
                  className="w-50"
                  label="版本号"
                  name="ver_no"
                  rules={[
                    {
                      required: true,
                      message: '版本号不能为空',
                    },
                  ]}
                >
                  <Input maxLength={20} placeholder="格式为vx.x.x"></Input>
                </Form.Item>
                <Form.Item
                  className="w-50"
                  label="更新日期"
                  name="update_date"
                  rules={[
                    {
                      required: true,
                      message: '请选择更新日期',
                    },
                  ]}
                >
                  <DatePicker
                    defaultPickerValue={null}
                    allowClear={false}
                    disabledDate={disabledDate}
                    value={
                      time
                        ? moment(time, 'YYYY-MM-DD')
                        : logInfo.update_date
                        ? moment(logInfo.update_date, 'YYYY-MM-DD')
                        : null
                    }
                    className="date_picker"
                    onChange={changeDate}
                    placeholder="请选择日期"
                  />
                </Form.Item>
              </div>
              <Form.Item label="更新日志" name="update_log">
                <RichFile defaultValue={undefined}></RichFile>
              </Form.Item>
            </div>
            <Form.Item>
              <div className="flex_end">
                <Space>
                  <MyButton onClick={() => setVisible(false)}>取消</MyButton>
                  <MyButton type="default" htmlType="submit">
                    {logInfo.sys_code ? '修改' : '新增'}
                  </MyButton>
                </Space>
              </div>
            </Form.Item>
          </Form>
        </ConfigProvider>
      </Drawer>

      <Modal
        bodyStyle={{ textAlign: 'center' }}
        centered
        onCancel={() => setDelLog(false)}
        title="删除更新日志"
        visible={delLog}
        getContainer={false}
        footer={[
          <Space size={[24]} className="model-btn">
            <MyButton onClick={() => setDelLog(false)}>取消</MyButton>
            <MyButton
              type="default"
              onClick={async () => {
                setDelLog(false)

                await dispatch(deleteUpdates(logInfo.update_log_id))
                await dispatch(
                  getUpdateLogList({
                    sys_id: sysId || defaultId,
                  })
                )
              }}
            >
              删除
            </MyButton>
          </Space>,
        ]}
      >
        <p>确定要删除更新日志吗？</p>
      </Modal>
    </div>
  )
}
