import {
  ADDCOMPS,
  GETCOMPINFO,
  GETCOMPLIST,
  GETCOMPLISTALL,
  GETCOMPLISTS,
  GETCOMPMODE,
  GETCOMPSTATUS,
  GETDICITEM,
  GETINTEGRATIONLIST,
} from '../constants'

const init = {
  total: 0,
  compList: [],
  compListAll: [],
  dicItem: [],
  compInfo: {},
  compMode: [],
  productCfgTemp: [],
  productCfgTempTotal: 0,
  compStatus: [],
  integrationList: [],
}
export default function getCompList(state = init, action) {
  if (action.type === GETCOMPLIST) {
    return {
      ...state,
      total: action.payload.total,
      compList: action.payload.data,
    }
  }
  if (action.type === GETCOMPLISTS) {
    return {
      ...state,
      compListAll: action.payload,
    }
  }
  if (action.type === ADDCOMPS) {
    return {
      ...state,
      compList: [...state.compList, action.payload],
    }
  }
  if (action.type === GETDICITEM) {
    return {
      ...state,
      dicItem: action.payload,
    }
  }
  if (action.type === GETCOMPMODE) {
    return {
      ...state,
      compMode: action.payload,
    }
  }
  if (action.type === GETCOMPSTATUS) {
    return {
      ...state,
      compStatus: action.payload,
    }
  }
  if (action.type === GETINTEGRATIONLIST) {
    localStorage.setItem('defaultSysId', action.payload.data[0]?.sys_id)

    return {
      ...state,
      integrationList: action.payload.data,
    }
  }

  return state
}
