import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { lazy, Suspense } from 'react'
import LoginCas from './pages/Login/loginCas'
import Layout from './pages/Layout'
import SaasLayout from './pages/SaasLayout'
import PrivateRoute from './components/PrivateRoute'
import history from './utils/history'
import Login from './pages/Login'
import Help from './pages/Help'
import SaasLogin from './pages/SaasLogin'
import WXLogin from './pages/WXLogin'
import WXHtml from './pages/WXHtml'
import ApplMemberPage from './pages/SaasLayout/ApplMemberPage'
import BindWxPage from './pages/SaasLayout/BindWxPage'

const PrivacyAgreement = lazy(() => import('./pages/PrivacyAgreement'))
const ContactInformation = lazy(() => import('./pages/ContactInformation'))
const ServiceAgreement = lazy(() => import('./pages/ServiceAgreement'))

const WDPrivacyAgreement = lazy(() => import('./pages/WDAgreement/PrivacyAgreement.js'))
const WDServiceAgreement = lazy(() => import('./pages/WDAgreement/ServiceAgreement.js'))

export default function App() {
  return (
    <Router history={history}>
      <Suspense fallback={<div></div>}>
        <Switch>
          <Redirect exact from="/" to="/home"></Redirect>
          <Route path="/loginHtml">{<Login></Login>}</Route>
          <Route path="/help/:id">{<Help></Help>}</Route>
          <Redirect exact from="/help" to="/help/10002.md"></Redirect>

          <Route path="/legal-agreement/tos.html">{<ServiceAgreement />}</Route>
          <Route path="/legal-agreement/privacy.html">
            {<PrivacyAgreement />}
          </Route>
          <Route path="/legal-agreement/about.html">
            {<ContactInformation />}
          </Route>

          <Route path="/legal-agreement/wd_tos.html">{<WDServiceAgreement />}</Route>
          <Route path="/legal-agreement/wd_privacy.html">
            {<WDPrivacyAgreement />}
          </Route>
          
          <Route path="/saas/invite">{<ApplMemberPage />}</Route>
          <Route path="/bindwxpage">{<BindWxPage />}</Route>
          <Route path="/login">{<LoginCas />}</Route>
          <Route path="/saasLogin">{<SaasLogin />}</Route>
          <Route path="/wxLogin">{<WXLogin />}</Route>
          <Route path="/wxHtml">{<WXHtml />}</Route>
          <PrivateRoute path="/home">{<Layout />}</PrivateRoute>
          <PrivateRoute path="/saas/noProject">{<Layout />}</PrivateRoute>
          <PrivateRoute path="/saas/guidePage">{<Layout />}</PrivateRoute>
          <PrivateRoute path="/saas">{<SaasLayout />}</PrivateRoute>
          <Redirect path="*" to="/home"></Redirect>
        </Switch>
      </Suspense>
    </Router>
  )
}
