import styles from "./index.module.scss";
import { useEffect, useRef, useState } from "react";
import logo from "../../assets/logo白.png";
import aImg from "../../assets/a.png";
import light from "../../assets/light.png";
import { Form, Input, Button, Checkbox, Spin, message, Modal } from "antd";
import { RightOutlined } from "@ant-design/icons";
import Cookie from "js-cookie";
import psl from "psl";
import { useGetState, useUnmount } from "ahooks";
import Api from "../../api/index.js";
import history from "../../utils/history.js";
import { useLocation } from "react-router-dom";
import { verificationCodeIntervalTime } from "../../setting.js";

export default function SassLogin() {
  const [type, setType] = useState("登录");
  const [loginQr, setLoginQr] = useState("");
  const [registerQr, setRegisterQr] = useState("");
  const [upLoading, setUpLoading] = useState(false);
  const [bindLoading, setBindLoading] = useState(false);

  const [agreeTerms, setAgreeTerms] = useState(false);
  const [countdownSecond, setCountdownSecond] = useState(0);
  const [countdownSecond2, setCountdownSecond2] = useState(0);

  const [uuid, setUuid] = useState(null);
  const [count, setCount, getCount] = useGetState(0);
  const [isOverdue, setIsOverdue] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);

  const [unionId, setUnionId] = useState(null);
  const [isModalOpen, setIsModalOpen, getIsModalOpen] = useGetState(false);

  const [form] = Form.useForm();
  const [bindForm] = Form.useForm();

  const timer = useRef();
  const location = useLocation();
  useUnmount(() => {
    //如果之前有定时器  清掉
    if (window.SMSCountdownTimer) {
      clearInterval(window.SMSCountdownTimer);
      window.SMSCountdownTimer = null;
    }
    if (window.SMSCountdownTimer2) {
      clearInterval(window.SMSCountdownTimer2);
      window.SMSCountdownTimer2 = null;
    }
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
  });

  //生成唯一id
  const randomUUID = () => {
    var s = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 32; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23];
    var uuid = s.join("");
    return uuid;
  };

  //获取二维码
  const getQrFunc = (uuid) => {
    setImgLoading(true);
    //登录 二维码
    Api.getQRCodeUrl(uuid, "2").then((res) => {
      setLoginQr(res.data?.data?.qrcode);
      setImgLoading(false);
    });
    //注册 二维码
    // Api.getQRCodeUrl(uuid, "1").then((res) => {
    //   setRegisterQr(res.data?.data?.qrcode);
    //   setImgLoading(false);
    // });
  };

  //刷新二维码
  const refreshRQ = () => {
    const uuid = randomUUID();
    console.log("唯一ID:", uuid);
    console.log(
      `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx886e069228435ad5&redirect_uri=https://sys-feature.touzhiwang.com/invite/${uuid}&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect`
    );
    setUuid(uuid);
    getQrFunc(uuid);
    if(timer.current){
      clearTimeout(timer.current);
      timer.current = null;
    }
    creatTimeout(uuid);
    setIsOverdue(false);
  };

  //获取登录状态
  const getLoginStatus = (uuid) => {
    // TODO: 调取获取登录状态的接口
    Api.getTokenByKey(uuid).then((res) => {
      console.log('登录状态!!!!!!!!',res.data.data);
      if (res.data.data?.ticket) {
        console.log(
          "🚀 ~ file: index.jsx:82 ~ Api.getTokenByKey ~ res:",
          res.data
        );
        loginFunc(res.data.data);
      }else if(res.data.data?.unionid){
        //微信扫码登录的 -> 微信未绑定手机号
        setUnionId(res.data.data?.unionid);
        if(!getIsModalOpen()){
          setIsModalOpen(true)
          setTimeout(()=>{
            bindForm.resetFields(["phoneNumber", "verificationCode"]);
          },100)
        }
      }
    });
    //没登录
    creatTimeout(uuid);
  };

  //设置 轮询获取登录状态的 定时器
  const creatTimeout = (uuid) => {
    //二维码超过十分钟 过期
    if (getCount() >= 60 * 10) {
      if (timer.current) {
        clearTimeout(timer.current);
        timer.current = null;
      }
      console.log("过期");
      setCount(0);
      setIsOverdue(true);
      return;
    }
    timer.current = setTimeout(() => {
      setCount(getCount() + 1);
      getLoginStatus(uuid);
    }, 2.5 * 1000);
  };

  useEffect(() => {
    document.title = "登录";
    //判断是否点过发送短信
    setSMSCountdownTimer(new Date().getTime());
    refreshRQ();
  }, []);

  //用户绑定企业
  const bindEnterprise = (enterpriseName, token) => {
    Api.bindEnterprise(enterpriseName, token).then((res) => {
      console.log("🚀 ~ file: register.jsx:57 ~ 用户创建企业 ~ res:", res);
      if (res.data.code === 0) {
        Api.switchEnterprise(null, res.data.data?.company_id, token).then(
          (res2) => {
            console.log(
              "🚀 ~ file: register.jsx:63 ~ 用户切换企业 ~ res2:",
              res2
            );
            if (res2.data.code === 0) {
              loginFunc(res2.data.data);
            } else {
              message.error(res.data.msg);
              setUpLoading(false);
            }
          }
        );
      } else {
        message.error(res.data.msg);
        setUpLoading(false);
      }
    });
  };

  //提交
  const onFinish = () => {
    if (upLoading) {
      return;
    }
    form.validateFields().then((res) => {
      const { enterpriseName, phoneNumber, verificationCode } = res;
      setUpLoading(true);
      if (type === "登录") {
        /* loginFunc */
        Api.loginFunc({
          phone: phoneNumber,
          verify_code: verificationCode,
          // use_token: true,
        }).then((res) => {
          if (res.data.code === 0) {
            loginFunc(res.data.data);
          } else {
            message.error(res.data.msg);
            setUpLoading(false);
          }
        });
      } else if (type === "注册") {
        Api.userLogon({
          phone: phoneNumber,
          verify_code: verificationCode,
        }).then((res2) => {
          console.log("🚀 ~ file: index.jsx:151 ~ 获取用户token ~ res:", res2);
          if (res2.data.code === 0) {
            const token = res2.data.data.token;
            bindEnterprise(enterpriseName, token);
          } else {
            message.error(res2.data.msg);
            setUpLoading(false);
          }
        });
      }
    });
  };

  //登录跳转
  const loginFunc = (data) => {
    let redirect = window.location.origin + "/loginHtml";
    let url = window.location.href;
    if (/redirect=([^#/?]+)/.test(url)) {
      redirect = /redirect=([^#/?]+)/.exec(url)[1];
    }
    window.location.href = decodeURIComponent(
      redirect + "?ticket=" + data.ticket
    );

    // setToken(data?.token || '')
    // setSuperGroup(data?.super_group || false)

    // if (location?.state?.form) {
    //   history.push(location?.state?.form)
    //   return
    // }
    // //登录的话跳转
    // let redirect = '/loginHtml'
    // let url = window.location.href
    // if (/redirect=([^#/?]+)/.test(url)) {
    //   redirect = /redirect=([^#/?]+)/.exec(url)[1]
    //   //开发
    //   if (redirect?.includes('localhost')) {
    //     redirect = redirect + `?token=${data?.token}`
    //   }
    // }
    // window.location.href = decodeURIComponent(redirect)
  };

  //登录/注册切换
  const changeType = () => {
    form.resetFields(["enterpriseName", "phoneNumber", "verificationCode"]);
    setAgreeTerms(false);
    const _type = type === "登录" ? "注册" : "登录";
    // getQrFunc(_type);
    setType(_type);
  };

  //验证码定时器
  const setSMSCountdownTimer = (time) => {
    const interval = verificationCodeIntervalTime;
    //如果之前有定时器  清掉
    if (window.SMSCountdownTimer) {
      clearTimeout(window.SMSCountdownTimer);
      window.SMSCountdownTimer = null;
    }
    //就时间缓冲
    const oldSMSCountdownSecond =
      window.localStorage.getItem("SMSCountdownSecond");
    //存入新的时间 缓存
    const diff = time - oldSMSCountdownSecond;
    //如果超过60s
    if (diff > interval * 1000) {
      return;
    } else {
      //未超过60s
      let intervalTime = interval * 1000 - diff;
      intervalTime = parseInt(intervalTime / 1000);
      if (intervalTime <= 0) {
        intervalTime = 0;
      }
      //修改
      if (setCountdownSecond) {
        setCountdownSecond(intervalTime);
      }
      //设置定时器
      setTimeout(() => {
        setSMSCountdownTimer(new Date().getTime());
      }, 1000);
    }
  };

  //发送短信
  const sendingSMS = () => {
    form.validateFields(["phoneNumber"]).then((res) => {
      const { phoneNumber } = res;
      //todo 调取发送验证码的接口
      Api.getVerifyCode({
        phone: phoneNumber,
        type: type === "注册" ? 1 : 2,
      }).then((res) => {
        if (res.data?.code === 0) {
          window.localStorage.setItem(
            "SMSCountdownSecond",
            new Date().getTime()
          );
          setSMSCountdownTimer(new Date().getTime());
        } else {
          message.error(res.data.msg);
        }
      });
    });
  };

  //验证码定时器
  const setSMSCountdownTimer2 = (time) => {
    const interval = verificationCodeIntervalTime;
    //如果之前有定时器  清掉
    if (window.SMSCountdownTimer2) {
      clearTimeout(window.SMSCountdownTimer2);
      window.SMSCountdownTimer2 = null;
    }
    //就时间缓冲
    const oldSMSCountdownSecond =
      window.localStorage.getItem("SMSCountdownSecond2");
    //存入新的时间 缓存
    const diff = time - oldSMSCountdownSecond;
    //如果超过60s
    if (diff > interval * 1000) {
      return;
    } else {
      //未超过60s
      let intervalTime = interval * 1000 - diff;
      intervalTime = parseInt(intervalTime / 1000);
      if (intervalTime <= 0) {
        intervalTime = 0;
      }
      //修改
      if (setCountdownSecond2) {
        setCountdownSecond2(intervalTime);
      }
      //设置定时器
      setTimeout(() => {
        setSMSCountdownTimer2(new Date().getTime());
      }, 1000);
    }
  };

  //发送短信
  const sendingSMS2 = () => {
    bindForm.validateFields(["phoneNumber"]).then((res) => {
      const { phoneNumber } = res;
      //todo 调取发送验证码的接口
      Api.getVerifyCode({
        phone: phoneNumber,
        type: 3,
      }).then((res) => {
        if (res.data?.code === 0) {
          window.localStorage.setItem(
            "SMSCountdownSecond2",
            new Date().getTime()
          );
          setSMSCountdownTimer2(new Date().getTime());
        } else {
          message.error(res.data.msg);
        }
      });
    });
  };

  const onBindFinish = () => {
    if (bindLoading) {
      return;
    }
    bindForm.validateFields().then((res) => {
      const {phoneNumber, verificationCode } = res;
      setBindLoading(true);
      //TODO: 绑定微信
      Api.bindWeChat({
        phone: phoneNumber,
        verify_code: verificationCode,
        unionid: unionId
        // use_token: true,
      }).then((res) => {
        if (res.data.code === 0) {
          loginFunc(res.data.data);
        } else {
          message.error(res.data.msg);
        }
        setBindLoading(false);
      });
    });
  }

  const handleCancel = () => {
    refreshRQ();
    setIsModalOpen(false);
  };

  return (
    <div className={styles.root}>
      <img src={logo} alt="" className="logoImg" />
      <div className="contentBox">
        <div className="contentLeftBox">
          <div
            className="topTitleBox"
            style={{ marginTop: type === "注册" ? "13%" : "20%" }}
          >
            {/* {type} */}
            扫码登录/注册
            <img src={light} alt="" className="lightImg" />
            <div className="line"></div>
          </div>
          <div className="qrBox">
            {isOverdue && (
              <div className="overdueBox" onClick={refreshRQ}>
                <svg
                  t="1701152961032"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  p-id="5490"
                  width="34"
                  height="34"
                >
                  <path
                    d="M512 919.552c-224.768 0-407.552-182.784-407.552-407.552 0-8.704 0.512-17.408 1.024-26.112l71.68 4.608c-0.512 7.168-0.512 14.336-0.512 21.504 0 185.344 150.528 335.872 335.872 335.872 86.528 0 168.448-32.768 230.912-92.16l49.152 52.224C716.288 880.128 616.96 919.552 512 919.552zM919.552 512h-71.68c0-11.776-0.512-23.552-2.048-35.328-17.92-171.52-161.28-300.544-334.336-300.544-67.584 0-132.096 19.968-187.904 57.344L284.16 174.08c67.072-45.568 145.92-69.632 227.84-69.632 209.408 0 384 156.672 405.504 365.056 1.536 13.824 2.048 28.16 2.048 42.496z"
                    p-id="5491"
                  ></path>
                  <path
                    d="M140.288 290.816L28.16 491.52c-3.072 5.12 1.024 11.776 6.656 11.776H258.56c6.144 0 9.728-6.144 6.656-11.776L153.6 290.816c-3.072-5.632-10.752-5.632-13.312 0zM870.4 675.84L758.272 475.136c-3.072-5.12 1.024-11.776 6.656-11.776h223.744c6.144 0 9.728 6.144 6.656 11.776L883.712 675.84c-2.56 5.12-10.24 5.12-13.312 0zM270.336 202.24a35.84 35.84 0 1 0 71.68 0 35.84 35.84 0 1 0-71.68 0zM728.576 784.896a35.84 35.84 0 1 0 71.68 0 35.84 35.84 0 1 0-71.68 0z"
                    p-id="5492"
                  ></path>
                </svg>
              </div>
            )}
            {imgLoading ? (
              <div className="imgLoadingBox">
                <Spin size="large"></Spin>
              </div>
            ) : (
              <img
                src={type === "登录" ? loginQr : registerQr}
                alt="请扫描二维码"
              />
            )}
          </div>
          <div className="qrTip">
            {!isOverdue ? `请使用手机微信扫码` : "二维码已失效，点击刷新"}
          </div>
        </div>
        <div className="contentRightBox">
          <div
            className="topTitleBox"
            style={{ marginTop: type === "注册" ? "13%" : "20%" }}
          >
            {/* type */}
            手机号登录/注册
            <img src={light} alt="" className="lightImg" />
            <div className="line"></div>
          </div>
          <div className="formBox">
            <Form
              colon={false}
              layout="vertical"
              autoComplete="off"
              form={form}
            >
              {type === "注册" && (
                <Form.Item
                  label="企业或组织名称"
                  name="enterpriseName"
                  rules={[
                    { required: true, message: "企业或组织名称不能为空" },
                  ]}
                >
                  <Input
                    style={{ width: "100%" }}
                    placeholder="请输入企业或组织名称"
                  ></Input>
                </Form.Item>
              )}

              <Form.Item
                label="手机号"
                name="phoneNumber"
                rules={[
                  { required: true, message: "手机号不能为空" },
                  {
                    pattern:
                      /^(13\d|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18\d|19[0-35-9])\d{8}$/,
                    message: "手机号格式不对",
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder="请输入手机号"
                ></Input>
              </Form.Item>
              <Form.Item
                label="验证码"
                name="verificationCode"
                rules={[
                  { required: true, message: "验证码不能为空" },
                  { pattern: /^[0-9]\d{5}$/, message: "验证码格式不对" },
                ]}
              >
                <div style={{ display: "flex" }}>
                  <Input
                    style={{ width: "calc(100% - 84px)", marginRight: "8px" }}
                    placeholder="请输入验证码"
                  ></Input>
                  <Button
                    className="verificationCodeBtn"
                    type="primary"
                    onClick={sendingSMS}
                    disabled={countdownSecond}
                  >
                    {countdownSecond
                      ? countdownSecond + "s后重试"
                      : "获取验证码"}
                  </Button>
                </div>
              </Form.Item>
            </Form>
            {type === "注册" && (
              <Checkbox
                value={agreeTerms}
                onChange={(e) => {
                  setAgreeTerms(e.target.checked);
                }}
                className="checkItem"
              >
                我已阅读并同意<a href="">服务条款、隐私政策</a>
              </Checkbox>
            )}
          </div>
          <div className="upBtn" onClick={onFinish}>
            {type}
          </div>
          <div className="tipBpx">
            登录即视为您已阅读并同意
            <a href="/legal-agreement/wd_tos.html" target="_blank">
              《服务协议》
            </a>
            和
            <a href="/legal-agreement/wd_privacy.html" target="_blank">
              《隐私协议》
            </a>
          </div>
          {/* <div className="formTip">
            {type === '注册' ? '已有账号' : '还没有账号'}，
            <span onClick={changeType}>
              {type === '注册' ? '去登录' : '立即注册'}
              <RightOutlined />
            </span>
          </div> */}
        </div>
        <img src={aImg} alt="" className="aImg" />
      </div>

      <Modal
        title={null}
        keyboard={false}
        maskClosable={false}
        visible={isModalOpen}
        centered
        footer={null}
        onCancel={handleCancel}
        wrapClassName="bindModal"
      >
        <div className="bindDiv">绑定手机号</div>
        <div className="tipsDiv">
          该微信为首次登录，需绑定手机号提高账号安全
        </div>
        <Form
          colon={false}
          autoComplete="off"
          form={bindForm}
          className="bindModelForm"
        >
          <Form.Item
            label="手机号"
            name="phoneNumber"
            rules={[
              { required: true, message: "手机号不能为空" },
              {
                pattern:
                  /^(13\d|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18\d|19[0-35-9])\d{8}$/,
                message: "手机号格式不对",
              },
            ]}
          >
            <Input style={{ width: "100%" }} placeholder="请输入手机号"></Input>
          </Form.Item>
          <Form.Item
            label="验证码"
            name="verificationCode"
            rules={[
              { required: true, message: "验证码不能为空" },
              { pattern: /^[0-9]\d{5}$/, message: "验证码格式不对" },
            ]}
          >
            <div style={{ display: "flex" }}>
              <Input
                style={{ width: "calc(100% - 84px)", marginRight: "8px" }}
                placeholder="请输入验证码"
              ></Input>
              <Button
                className="verificationCodeBtn"
                type="primary"
                onClick={sendingSMS2}
                disabled={countdownSecond2}
              >
                {countdownSecond2 ? countdownSecond2 + "s后重试" : "获取验证码"}
              </Button>
            </div>
          </Form.Item>
        </Form>
        <div className="upBtn" onClick={onBindFinish}>
          {bindLoading? '绑定中...' : '绑定'}
        </div>
      </Modal>
    </div>
  );
}
