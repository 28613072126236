import {
  Space,
  Input,
  message,
  Tabs,
  Modal,
  Breadcrumb,
  Image,
  Form,
  Button,
  Tag,
  Tooltip,
} from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import styles from './index.module.scss'
// import personalService from '../../service/homeService'
import qs from 'qs'
import { useDispatch, useSelector } from 'react-redux'
import MyButton from '../../../components/MyButton'
// import {
//   setCompanyInfo,
//   setPersonalInfo,
// } from 'libs/writing-workbench/src/reducer/writingWorkBench'
import defaultPt from '../../images/default_pt.png'
import dftComlogo from '../../images/qiyelogo.png'
import {
  editEmail,
  editMobile,
  editPersonalInfo,
  getBindStatus,
  getWxQcd,
  logoutComp,
  switchCoList,
} from '../../../store/actions/user'
import { getUserInfo } from '../../../store/actions/login'
import shanchu from '../../images/shanchu.png'

import Api from '../../../api/index'
import { useHistory } from 'react-router-dom'
import { judgingCharacters } from '../../../utils/util'
import { verificationCodeIntervalTime } from '../../../setting'
import { getDeploy } from '../../../utils/cookie'
let isComp = false
const personalList = {
  individual_id: '用户ID',
  mobile_no: '手机号',
  wx_nickname: '微信',
  pf_photo: '头像',
  full_name: '姓名',
  nickname: '昵称',
  email: '邮箱',
}
const compList = {
  pf_photo: '头像',
  full_name: '姓名',
  nickname: '昵称',
  email: '邮箱',
  // org_full_name: '部门',
}

const allowTypes = ['jpg', 'jpeg', 'png', 'bmp']
const maxFileSize = 5 * 1024 * 1024

//公共div
const PublicDiv = (props) => {
  const { item, userId, co_name, comInfo, userInfo, info, enterprise_id } =
    props
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [setUserForm] = Form.useForm()
  const [numForm] = Form.useForm()
  const [emailForm] = Form.useForm()
  const inputRef = useRef(null)
  const fileRef = useRef(null)
  const changeUserForm = useRef(null)
  const [inputShow, setInputShow] = useState(false) //输入框展示
  const [inputType, setInputType] = useState('')
  const [defaultVal, setDefaultVal] = useState('')
  const [changeModal, setChangeModal] = useState(false)
  const [userNameModal, setUserNameModal] = useState(false)
  const [numModal, setNumModal] = useState(false)
  const [emailModal, setEmailModal] = useState(false)
  const [changeUserModal, setChangeUserModal] = useState(false)
  const [countdownSecond, setCountdownSecond] = useState(0)
  const [visible, setVisible] = useState(false)
  const [qrcode, setQrcode] = useState(null)
  const [imgs, setImgs] = useState([
    'https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg',
    'https://gw.alipayobjects.com/zos/antfincdn/aPkFc8Sj7n/method-draw-image.svg',
  ])
  useEffect(() => {
    if (inputShow) {
      inputRef.current.focus()
    }
  }, [inputShow])
  //保存
  const savaInfo = async (type, data) => {
    console.log(comInfo)
    const params = {}
    params[type] = defaultVal || data || ''
    params.co_id = isComp ? comInfo.co_id : ''
    const res = await dispatch(editPersonalInfo(params))
    setInputShow(false)
    console.log(res)
    if (res.data.code === 0) {
      if (isComp) {
        props.getCoList()
      }
      await dispatch(getUserInfo())
      return message.success('修改成功')
    } else {
      return message.error(res.data.msg)
    }
  }
  //上传图片
  const onChange = async (e) => {
    e.persist()
    const type = e?.target?.files[0]?.name?.split('.')[1]
    const size = e?.target?.files[0]?.size
    if (!allowTypes?.includes(type)) {
      message.info(`请上传格式为${allowTypes.join('、')}的图片`)
      return
    }
    if (size > maxFileSize) {
      message.info('上传的图片不能大于5MB')
      return
    }
    const formData = new FormData()
    formData.append('pf_photo', e.target.files[0])
    formData.append('co_id', isComp ? comInfo.co_id : '')
    const res = await dispatch(editPersonalInfo(formData))
    if (res.data.code === 0) {
      if (isComp) {
        props.getCoList()
      }
      await dispatch(getUserInfo())
      return message.success('头像修改成功')
    }
  }

  const onEditUserName = async () => {
    const values = form.getFieldsValue()
    const res = await dispatch(editPersonalInfo(values))
    setChangeUserModal(false)
    if (res.data.code === 0) {
      await dispatch(getUserInfo())
      return message.success('修改成功')
    } else {
      return message.error(res.data.msg)
    }
  }

  const onEditNum = () => {
    numForm.validateFields().then(async (res1) => {
      console.log(res1)
      const res = await dispatch(editMobile(res1))
      if (res.data.code === 0) {
        setNumModal(false)
        numForm?.resetFields()
        await dispatch(getUserInfo())
        return message.success('修改成功')
      }
    })
  }
  const onEditEmail = () => {
    emailForm.validateFields().then(async (res1) => {
      console.log(res1)
      const res = await dispatch(editEmail(res1))
      if (res.data.code === 0) {
        setEmailModal(false)
        emailForm?.resetFields()
        await dispatch(getUserInfo())
        return message.success('修改成功')
      }
    })
  }

  const setSMSCountdownTimer = (time) => {
    //如果之前有定时器  清掉
    if (window.SMSCountdownTimer) {
      clearInterval(window.SMSCountdownTimer)
      window.SMSCountdownTimer = null
    }
    //倒计时存缓存
    window.localStorage.setItem('SMSCountdownSecond', time)
    //设置定时器
    window.SMSCountdownTimer = setInterval(() => {
      //倒计时计时
      let SMSCountdownSecond = window.localStorage.getItem('SMSCountdownSecond')
      SMSCountdownSecond = SMSCountdownSecond - 1
      if (SMSCountdownSecond <= 0) {
        SMSCountdownSecond = 0
      }
      window.localStorage.setItem('SMSCountdownSecond', SMSCountdownSecond)
      //修改
      if (setCountdownSecond) {
        setCountdownSecond(SMSCountdownSecond)
      }
      //倒计时结束
      if (SMSCountdownSecond <= 0) {
        clearInterval(window.SMSCountdownTimer)
        window.SMSCountdownTimer = null
      }
    }, 1000)
  }
  const [textType, setTextType] = useState('')
  const [loadingStatus, setLoadingStatus] = useState(false)
  const sendingSMS = () => {
    console.log(textType)
    if (textType === 'mobile_no') {
      numForm.validateFields(['phone']).then((res) => {
        const { phone } = res
        console.log(res)
        //todo 调取发送验证码的接口
        Api.getVerifyCode({ phone: phone, type: 3 }).then((res) => {
          if (res.data?.code === 0) {
            setSMSCountdownTimer(verificationCodeIntervalTime)
          } else {
            message.error(res.data.msg)
          }
        })
      })
    } else if (item === 'email') {
      emailForm.validateFields(['email']).then((res) => {
        setLoadingStatus(true)

        const { email } = res
        //todo 调取发送验证码的接口
        Api.getVerifyCode({ email: email, type: 4 }).then((res) => {
          if (res.data?.code === 0) {
            setSMSCountdownTimer(verificationCodeIntervalTime)

            setLoadingStatus(false)
          } else {
            message.error(res.data.msg)
          }
        })
      })
    }
  }

  //生成唯一id
  const randomUUID = () => {
    var s = []
    var hexDigits = '0123456789abcdef'
    for (var i = 0; i < 32; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    }
    s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23]
    var uuid = s.join('')
    return uuid
  }

  const [timerId, setTimerId] = useState(null)
  const timerIdRef = useRef(null)

  const startTimer = (uuid) => {
    let values = {}
    timerIdRef.current = setInterval(async () => {
      try {
        values.unique_key = uuid
        const res = await dispatch(getBindStatus(values, false))
        if (res.data.data) {
          stopTimer()
          setVisible(false)
          dispatch(getUserInfo())
        }
      } catch (error) {
        console.error(error)
      }
    }, 4000) // 设置定时器每隔4秒执行一次
    setTimerId(timerIdRef.current)
  }

  const stopTimer = () => {
    clearInterval(timerIdRef.current)
    setTimerId(null)
  }

  //绑定或更换微信
  const setWxBindFn = async () => {
    const uuid = randomUUID()
    let params = {}
    params.unique_key = uuid
    const res = await dispatch(getWxQcd(params, false))
    if (res.data.code === 0) {
      //开启定时器
      startTimer(uuid)
    }
    setQrcode(res.data.data.qrcode)
    setVisible(true)
  }
  return (
    <div
      className="personal_div"
      co_name={info.co_type_code !== 'INDIV_ORG' ? co_name : ''}
      enterprise_id={
        info.co_type_code !== 'INDIV_ORG' && enterprise_id && info.co_user_id
          ? '企业ID：' + enterprise_id
          : ''
      }
    >
      {/* 第一列 */}
      <div className="personal_list">
        {isComp ? compList[item] : personalList[item]}
      </div>
      {/* 第二列 */}
      {inputShow ? (
        <div className="personal_info">
          <Input
            value={defaultVal}
            onChange={async (e) => {
              if (inputType === 'full_name') {
                if (judgingCharacters(e.target.value, 50)) {
                  setDefaultVal(e.target.value)
                }
              } else if (inputType === 'nickname') {
                if (judgingCharacters(e.target.value, 32)) {
                  setDefaultVal(e.target.value)
                }
              }
            }}
            ref={inputRef}
            style={{ width: 280 }}
          ></Input>
        </div>
      ) : (
        <Tooltip
          title={item !== 'pf_photo' && userInfo[item]}
          placement="leftTop"
        >
          <div className="personal_info">
            {item === 'nickname' ? (
              userInfo['nickname'] ? (
                userInfo['nickname']
              ) : userInfo['wx_nickname'] ? (
                userInfo['wx_nickname']
              ) : (
                '未设置'
              )
            ) : item === 'pf_photo' ? (
              userInfo[item] ? (
                <img
                  onClick={() => {
                    if (info?.co_id && info?.co_user_id) {
                      fileRef.current.click()
                    }
                    // setChangeModal(true)
                  }}
                  height={50}
                  src={userInfo[item]}
                  alt=""
                />
              ) : (
                <img
                  onClick={() => {
                    if (info?.co_id && info?.co_user_id) {
                      fileRef.current.click()
                    }
                  }}
                  height={50}
                  src={defaultPt}
                  alt=""
                />
              )
            ) : userInfo[item] ? (
              userInfo[item]
            ) : item === 'user_name' ? (
              <span>
                未设置
                <span className="tip_text">设置后可以使用账号密码登录</span>
              </span>
            ) : item === 'mobile_no' ? (
              <span>
                未设置
                {getDeploy() !== 'BOX' && (
                  <span className="tip_text">设置后可以使用手机号登录</span>
                )}
              </span>
            ) : item === 'wx_nickname' ? (
              <span>
                未绑定
                <span className="tip_text">绑定后可以使用微信扫码登录</span>
              </span>
            ) : (
              '未设置'
            )}
          </div>
        </Tooltip>
      )}
      {/* 第三列 */}
      {userInfo && (
        <div className="personal_setting">
          {!inputShow && !userInfo[item] && (
            <a
              style={{
                pointerEvents:
                  (!info?.co_user_id || !info?.co_id) &&
                  (item === 'pf_photo' ||
                    item === 'full_name' ||
                    item === 'nickname')
                    ? 'none'
                    : '',
                color:
                  (!info?.co_user_id || !info?.co_id) &&
                  (item === 'pf_photo' ||
                    item === 'full_name' ||
                    item === 'nickname')
                    ? '#808080'
                    : '#0068b2',
              }}
              onClick={async () => {
                setInputType(item)
                if (item === 'pf_photo') {
                  fileRef.current.click()
                } else if (item === 'user_name') {
                  //设置账号/密码弹窗
                  setUserNameModal(true)
                } else if (item === 'mobile_no') {
                  setTextType(item)
                  //设置手机号
                  setNumModal(true)
                } else if (item === 'email') {
                  //设置邮箱
                  setTextType(item)
                  setEmailModal(true)
                } else if (item === 'wx_nickname') {
                  //绑定微信
                  setWxBindFn()
                } else {
                  setInputShow(true)
                }
              }}
            >
              <span>
                {item === 'pf_photo' ||
                item === 'org_full_name' ||
                item === 'individual_id'
                  ? ''
                  : '设置'}
              </span>
            </a>
          )}

          {inputShow && <a onClick={() => savaInfo(item)}>保存</a>}
          {inputShow && (
            <a
              onClick={() => {
                setDefaultVal('')
                setInputShow(false)
              }}
            >
              取消
            </a>
          )}
          {userInfo[item] && !inputShow && (
            <a
              style={{
                pointerEvents:
                  (!info?.co_user_id || !info?.co_id) &&
                  (item === 'pf_photo' ||
                    item === 'full_name' ||
                    item === 'nickname')
                    ? 'none'
                    : '',
                color:
                  (!info?.co_user_id || !info?.co_id) &&
                  (item === 'pf_photo' ||
                    item === 'full_name' ||
                    item === 'nickname')
                    ? '#808080'
                    : '#0068b2',
              }}
              onClick={() => {
                if (item === 'individual_id') {
                  return
                }
                setInputType(item)
                if (item === 'pf_photo') {
                  fileRef.current.click()
                } else if (item === 'user_name') {
                  //修改账号弹窗
                  setChangeUserModal(true)
                  setDefaultVal(userInfo[item])
                } else if (item === 'mobile_no') {
                  setTextType(item)
                  //设置手机号
                  setNumModal(true)
                } else if (item === 'email') {
                  //设置邮箱
                  setTextType(item)
                  setEmailModal(true)
                } else if (item === 'wx_nickname') {
                  //更换微信
                  setWxBindFn()
                } else {
                  setInputShow(true)
                  setDefaultVal(userInfo[item])
                }
              }}
            >
              {item === 'individual_id' ? (
                <span style={{ color: 'transparent' }}>设置</span>
              ) : item === 'pf_photo' || item === 'mobile_no' ? (
                '更换'
              ) : (
                '修改'
              )}
            </a>
          )}
        </div>
      )}
      {/* 输入框 */}
      <input
        style={{ display: 'none' }}
        type="file"
        ref={fileRef}
        onChange={onChange}
      />
      <Modal
        centered
        title="更换头像"
        visible={changeModal}
        footer={[
          <Space size={[24]} className="">
            <MyButton onClick={() => setChangeModal(false)}>取消</MyButton>
            <MyButton
              type="default"
              // onClick={() => delUser(memberInfo.co_user_id)}
            >
              更换
            </MyButton>
          </Space>,
        ]}
      >
        <Image.PreviewGroup>
          {imgs.map((item) => (
            <Image
              onClick={async (e) => {
                e.persist()
                console.log(e, userId)
                await dispatch(
                  editPersonalInfo({ pf_photo: e.target.currentSrc })
                )
              }}
              preview={false}
              width={50}
              src={item}
            />
          ))}
        </Image.PreviewGroup>

        <div style={{ textAlign: 'center', marginTop: 24, color: '#0068b2' }}>
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              fileRef.current.click()
            }}
          >
            上传头像
          </span>
        </div>
      </Modal>
      <Modal
        centered
        destroyOnClose={true}
        maskClosable={false}
        title="设置账号"
        visible={userNameModal}
        onCancel={() => {
          setUserNameModal(false)
        }}
        footer={[
          <Space size={[24]} className="model-btn">
            <MyButton onClick={() => setUserNameModal(false)}>取消</MyButton>
            <MyButton
              type="default"
              // onClick={() => delUser(memberInfo.co_user_id)}
            >
              确认
            </MyButton>
          </Space>,
        ]}
      >
        <Form form={setUserForm} colon={false} layout="vertical">
          <Form.Item
            label="账户（用户名）"
            name="user_name"
            rules={[
              {
                pattern: /^(\D)[^\s]+(\s+[^\s]+)*.{2,20}$/,
                message: '4~20个字符，不能以数字或空格开头',
              },
            ]}
          >
            <Input placeholder="4~20个字符，不能以数字开头"></Input>
          </Form.Item>
          <Form.Item
            label="密码"
            name="new_password_1"
            rules={[
              {
                pattern:
                  /(?!.*\s)(?!^[\u4e00-\u9fa5]+$)(?!^[0-9]+$)(?!^[A-z]+$)(?!^[^A-z0-9]+$)^.{8,16}$/,
                message: '8-16位，至少包含数字/字母/字符2种组合',
              },
            ]}
          >
            <Input.Password placeholder="8-16位，至少包含数字/字母/字符2种组合"></Input.Password>
          </Form.Item>
          <Form.Item
            label="确认密码"
            name="new_password_2"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value) {
                    return Promise.resolve()
                  }
                  if (value?.length < 8 || value?.length > 16) {
                    return Promise.reject(
                      new Error('8-16位，至少包含数字/字母/字符2种组合')
                    )
                  }
                  const new_password_1 = getFieldValue('new_password_1')
                  if (new_password_1 !== value) {
                    return Promise.reject(new Error('两次输入的密码不一致'))
                  }
                  return Promise.resolve()
                },
              }),
            ]}
          >
            <Input.Password placeholder="再次输入登录密码"></Input.Password>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        centered
        destroyOnClose={true}
        maskClosable={false}
        title="修改账号"
        visible={changeUserModal}
        onCancel={() => {
          setChangeUserModal(false)
        }}
        footer={[
          <Space size={[24]} className="model-btn">
            <MyButton onClick={() => setChangeUserModal(false)}>取消</MyButton>
            <MyButton type="default" onClick={onEditUserName}>
              确认
            </MyButton>
          </Space>,
        ]}
      >
        <Form form={form} colon={false} layout="vertical">
          <Form.Item
            label="账户（用户名）"
            name="user_name"
            rules={[
              {
                pattern: /^(\D)[^\s]+(\s+[^\s]+)*.{2,20}$/,
                message: '4~20个字符，不能以数字开头',
              },
            ]}
          >
            <Input
              defaultValue={defaultVal}
              placeholder="4~20个字符，不能以数字开头"
            ></Input>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        getContainer={() => document.getElementsByClassName('right_content')[0]}
        centered
        destroyOnClose={true}
        maskClosable={false}
        title="设置手机号"
        visible={numModal}
        onCancel={() => {
          setNumModal(false)
          numForm?.resetFields()
        }}
        footer={[
          <Space size={[24]} className="model-btn">
            <MyButton
              onClick={() => {
                setNumModal(false)
                numForm?.resetFields()
              }}
            >
              取消
            </MyButton>
            <MyButton type="default" onClick={onEditNum}>
              确认
            </MyButton>
          </Space>,
        ]}
      >
        <Form autoComplete="off" form={numForm} colon={false} layout="vertical">
          <Form.Item
            validateTrigger="onBlur"
            required
            label="手机号（+86）"
            name="phone"
            rules={[
              { required: true, message: '手机号不能为空' },
              {
                pattern:
                  /^(13\d|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18\d|19[0-35-9])\d{8}$/,
                message: '手机号格式错误',
              },
            ]}
          >
            <Input
              maxLength={11}
              defaultValue={defaultVal}
              placeholder="请输入手机号"
            ></Input>
          </Form.Item>
          {getDeploy() !== 'BOX' && (
            <Form.Item
              label="验证码"
              name="verify_code"
              rules={[
                { required: true, message: '验证码不能为空' },
                { pattern: /^[0-9]\d{5}$/, message: '验证码错误' },
              ]}
            >
              <div style={{ display: 'flex' }}>
                <Input
                  style={{ width: 'calc(100%- 84px)', marginRight: '8px' }}
                  placeholder="请输入验证码"
                ></Input>
                <Button
                  className="verificationCodeBtn"
                  type="primary"
                  onClick={sendingSMS}
                  disabled={countdownSecond}
                >
                  {countdownSecond ? countdownSecond + 's后重试' : '获取验证码'}
                </Button>
              </div>
            </Form.Item>
          )}
        </Form>
      </Modal>
      <Modal
        getContainer={() => document.getElementsByClassName('right_content')[0]}
        centered
        destroyOnClose={true}
        maskClosable={false}
        title="设置邮箱"
        visible={emailModal}
        onCancel={() => {
          setEmailModal(false)
          emailForm?.resetFields()
        }}
        footer={[
          <Space size={[24]} className="model-btn">
            <MyButton
              onClick={() => {
                setEmailModal(false)
                emailForm?.resetFields()
              }}
            >
              取消
            </MyButton>
            <MyButton type="default" onClick={onEditEmail}>
              确认
            </MyButton>
          </Space>,
        ]}
      >
        <Form
          autoComplete="off"
          form={emailForm}
          colon={false}
          layout="vertical"
        >
          <Form.Item
            validateTrigger="onBlur"
            required
            label="邮箱"
            name="email"
            rules={[
              { required: true, message: '邮箱不能为空' },
              {
                pattern: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
                message: '邮箱格式错误',
              },
            ]}
          >
            <Input placeholder="请输入邮箱"></Input>
          </Form.Item>
          {getDeploy() !== 'BOX' && (
            <Form.Item
              validateTrigger="onChange"
              label="验证码"
              name="verify_code"
              rules={[
                { required: true, message: '验证码不能为空' },
                { pattern: /^[0-9]\d{5}$/, message: '验证码错误' },
              ]}
            >
              <div style={{ display: 'flex' }}>
                <Input
                  style={{ width: 'calc(100%- 84px)', marginRight: '8px' }}
                  placeholder="请输入验证码"
                ></Input>
                <Button
                  className="verificationCodeBtn"
                  type="primary"
                  onClick={sendingSMS}
                  disabled={countdownSecond}
                  loading={loadingStatus}
                >
                  {loadingStatus
                    ? '发送中'
                    : countdownSecond
                    ? countdownSecond + 's后重试'
                    : '获取验证码'}
                </Button>
              </div>
            </Form.Item>
          )}
        </Form>
        {countdownSecond !== 0 ? (
          <span style={{ color: '#0068b2', fontSize: '12px' }}>
            验证码已发送到你的邮箱，请查收！
          </span>
        ) : (
          ''
        )}
      </Modal>
      <Modal
        centered
        className="bindwx_modal"
        style={{ width: 180, height: 222 }}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={() => {
          setVisible(false)
        }}
        visible={visible}
        footer={[]}
      >
        <div
          style={{
            color: '#313131',
            fontWeight: 400,
            textAlign: 'center',
            paddingTop: 24,
          }}
        >
          {qrcode && <img width={160} height={160} src={qrcode} alt="" />}
        </div>
      </Modal>
    </div>
  )
}

export default function PersonalSettings() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [colist, setColist] = useState([])
  const [keys, setKeys] = useState('')
  const [compInfo, setComInfoModal] = useState(false)
  const [logoutCompModal, setLogoutCompModal] = useState(false)
  const [logoutInfoModal, setLogoutInfoModal] = useState(false)

  const [comInfo, setComInfo] = useState({})
  console.log(comInfo)
  const getCoList = async () => {
    const res = await dispatch(switchCoList())
    setColist(res.data.data)
    setComInfo(
      res?.data?.data?.find((item) => {
        return item?.co_id === comInfo?.co_id
      })
    )
  }
  useEffect(() => {
    getCoList()
  }, [])
  const userInfo = useSelector((state) => state.login.userInfo)
  const filteredObject = Object.keys(personalList).reduce((result, key) => {
    if (userInfo[key] !== undefined) {
      result[key] = userInfo[key]
    }
    if (getDeploy() === 'BOX') {
      delete result.wx_nickname
    }
    if (!userInfo.co_id) {
      delete result.full_name
      delete result.pf_photo
      delete result.nickname
      delete result.email
      return result
    } else {
      return result
    }
  }, {})
  const compFilter = Object.keys(compList).reduce((result, key) => {
    if (comInfo?.[key] !== undefined) {
      result[key] = comInfo?.[key]
    }
    return result
  }, {})
  compFilter.email = userInfo?.email
  return (
    <div className={styles.root}>
      <div className="right_content">
        <Breadcrumb>
          <Breadcrumb.Item>个人设置</Breadcrumb.Item>
        </Breadcrumb>
        <Tabs
          className="contanier"
          defaultActiveKey="1"
          onChange={(e) => {
            setKeys(e)
            if (e === '2') {
              getCoList()
            }
          }}
        >
          <Tabs.TabPane tab="个人设置" key="1">
            {Object.keys(filteredObject).map((item, index) => {
              return (
                <PublicDiv
                  key={index}
                  item={item}
                  info={userInfo}
                  userInfo={filteredObject}
                  userId={userInfo?.co_user_id}
                  co_name={userInfo?.co_name}
                  enterprise_id={userInfo?.enterprise_id}
                  // companyInfo={companyInfo}
                ></PublicDiv>
              )
            })}
          </Tabs.TabPane>
          {(colist || [])?.filter((_) => _.co_type_code !== 'INDIV_ORG')
            ?.length > 0 &&
            userInfo?.co_type_code !== 'INDIV_ORG' &&
            getDeploy() !== 'BOX' && (
              <Tabs.TabPane tab="企业/组织设置" key="2" className="colist_css">
                {(colist || [])
                  ?.filter((_) => _.co_type_code !== 'INDIV_ORG')
                  .map((i, index) => {
                    return (
                      <div className="card_item">
                        <div style={{ width: 130 }}>
                          <img
                            onClick={() => {
                              isComp = true
                              setComInfoModal(true)
                              setComInfo(i)
                            }}
                            width={110}
                            height={120}
                            src={dftComlogo}
                            alt=""
                          />
                        </div>
                        <div className="co_card_right">
                          <div className="co_text">{i.co_name}</div>
                          <div style={{ color: '#b1b1b1', fontSize: '12px' }}>
                            企业ID：{i.enterprise_id}
                          </div>
                          <div className="co_user_name">
                            <Tooltip
                              title={
                                i?.full_name ||
                                userInfo?.show_name ||
                                userInfo?.assist_name
                              }
                            >
                              <span
                                style={{
                                  display: 'inline-block',
                                  maxWidth: '200px',
                                }}
                                className="text_overflow_css"
                              >
                                {i?.nickname ||
                                  i?.full_name ||
                                  userInfo?.show_name ||
                                  userInfo?.assist_name}
                              </span>
                            </Tooltip>
                            <Tag
                              style={{
                                borderRadius: '2px',
                                marginLeft: '8px',
                                padding: '0px 4px',
                              }}
                            >
                              <span
                                style={{
                                  color: '#FF5252',
                                  fontSize: '10px',
                                }}
                              >
                                {i.role_tag}
                              </span>
                            </Tag>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                {/* {Object.keys(companyInfo).map((item, index) => {
            return (
              <PublicDiv
                key={index}
                item={item}
                companyInfo={companyInfo}
                is_co={true}
                isAdmin={isAdmin}
              ></PublicDiv>
            )
          })} */}
              </Tabs.TabPane>
            )}
        </Tabs>
        {/* <div className="unsubscribe_btn">
          <MyButton
            width={200}
            bgcolor="#FE545F"
            type="default"
            onClick={() => {
              // transferFn()
            }}
          >
            {keys === '1' ? '注销账户' : '退出该企业'}
          </MyButton>
        </div> */}
        <Modal
          centered
          destroyOnClose={true}
          maskClosable={false}
          width={900}
          getContainer={() =>
            document.getElementsByClassName('right_content')[0]
          }
          onCancel={() => {
            isComp = false
            setComInfoModal(false)
          }}
          visible={compInfo}
          footer={[
            <Space
              size={[24]}
              className="model-btn"
              style={{ padding: '16px' }}
            >
              <MyButton
                type="default"
                bgcolor="#FE545F"
                width={200}
                onClick={() => {
                  setLogoutCompModal(true)
                }}
              >
                退出该企业
              </MyButton>
            </Space>,
          ]}
        >
          <div className="comp">
            <div className="card_item">
              <div style={{ width: 130 }}>
                <img width={110} height={120} src={dftComlogo} alt="" />
              </div>
              <div className="co_card_right">
                <div className="co_text">{comInfo?.co_name}</div>
                <div style={{ color: '#b1b1b1', fontSize: '12px' }}>
                  企业ID：{comInfo?.enterprise_id}
                </div>
                <div className="co_user_name">
                  <Tooltip
                    title={
                      comInfo?.nickname ||
                      compInfo?.full_name ||
                      userInfo?.user_name
                    }
                  >
                    <span
                      style={{
                        display: 'inline-block',
                        marginRight: 4,
                      }}
                      className="text_overflow_css"
                    >
                      {comInfo?.nickname ||
                        compInfo?.full_name ||
                        userInfo?.user_name}
                    </span>
                  </Tooltip>
                  <span style={{ color: '#9E9E9E' }}>{comInfo?.role_tag}</span>
                </div>
              </div>
            </div>
            {Object.keys(compFilter).map((item, index) => {
              return (
                <PublicDiv
                  key={index}
                  item={item}
                  info={userInfo}
                  userInfo={compFilter}
                  userId={userInfo?.co_user_id}
                  // companyInfo={companyInfo}
                  comInfo={comInfo}
                  getCoList={getCoList}
                ></PublicDiv>
              )
            })}
          </div>
        </Modal>
        <Modal
          centered
          maskClosable={false}
          visible={logoutCompModal}
          onCancel={() => setLogoutCompModal(false)}
          footer={[
            <Space size={[24]} className="model-btn">
              <MyButton onClick={() => setLogoutCompModal(false)}>
                取消
              </MyButton>
              <MyButton
                type="default"
                bgcolor="#FE545F"
                onClick={async () => {
                  if (comInfo?.is_creator) {
                    setLogoutInfoModal(true)
                  } else {
                    const res = await dispatch(
                      logoutComp({
                        co_id: comInfo?.co_id,
                      })
                    )
                    if (res.data.code === 0) {
                      setLogoutCompModal(false)
                      setComInfoModal(false)
                      getCoList()
                      await dispatch(switchCoList())
                      await dispatch(getUserInfo())
                      message.success('退出企业成功')
                      if (compList?.length === 0) {
                        history.push('/saas/noProject')
                      }
                    } else {
                      return message.error(res.data.msg)
                    }
                  }
                }}
              >
                退出
              </MyButton>
            </Space>,
          ]}
        >
          <div style={{ textAlign: 'center' }}>
            <img style={{ marginBottom: 24 }} src={shanchu} alt="" />
            <p>确定要退出该企业吗？</p>
          </div>
        </Modal>
        <Modal
          centered
          maskClosable={false}
          visible={logoutInfoModal}
          onCancel={() => setLogoutInfoModal(false)}
          footer={[
            <Space size={[24]} className="model-btn">
              <MyButton
                type="default"
                onClick={async () => {
                  setLogoutCompModal(false)
                  setLogoutInfoModal(false)
                }}
              >
                确定
              </MyButton>
            </Space>,
          ]}
        >
          <div style={{ textAlign: 'center' }}>
            <p>您是企业创建者，不允许退出！</p>
            <p style={{ color: '#9e9e9e' }}>请先将创建者权限移交后再退出</p>
          </div>
        </Modal>
      </div>
    </div>
  )
}
